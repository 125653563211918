import React from 'react'
import { Link } from 'gatsby'
import './ATCBar.scss'
import cart from './Cart.png'
import {gsap} from 'gsap'

const ATCBar = () => {
	const atcBar = React.useRef();
	React.useEffect(()=>{
		let tl = gsap.timeline();
		tl
		.delay(1.6)
		.fromTo(atcBar.current, {y: '80'}, {y: '0', duration: 1.6})
	},[])
	return (
		<div ref={atcBar} className="atc-bar d-flex">
			<div className="border-gradient"></div>
			<span className="roboto-txt-sm">Shop Now</span>
			<img src={cart} alt="cart" />
			<Link to="/shop" className="atc-link" />
		</div>
	)
}

export default ATCBar
