import React, { useContext } from 'react'
import styled from 'styled-components'
import { gsap, TimelineMax} from 'gsap'
import {CSSPlugin} from 'gsap/CSSPlugin'

import StoreContext from '~/context/StoreContext'
import logo from '../../images/kept.svg'

const Background = styled.div`
    background-color: #e9dccd;
    height: 100%;
    width: 100%;
    z-index: 999999;
    position: fixed;
    top: 0;
    left: 0;
`
const Logo = styled.img`
    position: fixed;
    top: calc(50% - 60px);
    left: calc(50% - 30px);
    z-index: 9999999;
    transform: rotate(90deg);
    width: 30px;
`

const Intro = () => {

    const {
      store: { sidebarMenuOpen, sidebarCartOpen },
    } = useContext(StoreContext)
    
    gsap.registerPlugin(CSSPlugin)
    const logoRef = React.useRef();
    const backgroundRef = React.useRef();
    React.useEffect(()=>{
        const body = document.querySelector('body');
        // lock scroll
        body.classList.add('overflow:hidden')
        // Object.assign(body.style, { height: "100vh", overflowY: "hidden"});
        let tl = new TimelineMax({
            // unlock scroll after animations complete
            onComplete: function(){
                if(!sidebarMenuOpen && !sidebarCartOpen) {
                    body.classList.remove('overflow:hidden')
                    // Object.assign(body.style, { height: "auto", overflowY: "visible"});
                }
                
            }
        });
        tl
        .set(backgroundRef.current, {width: '100%'})
        .set(logoRef.current, {autoAlpha:1})
        .from(logoRef.current, 1, {translateY: '80'})
        .to(logoRef.current, 0.2, {autoAlpha:0})
        .to(backgroundRef.current, 1, {width: '0'})
    }, [])
    return(
        <>
            <Background ref={backgroundRef}></Background>
            <Logo ref={logoRef} src={logo} alt="Kept Logo" />
        </>
    );
}

export default Intro;