import React from 'react'
import { Link } from 'gatsby'
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'


import './Hero1.scss'


const Hero1 = ({ t1Ref }) => {
  const h1Ref = React.useRef();
  const pRef = React.useRef();
  React.useEffect(()=>{
    gsap.registerPlugin(ScrollTrigger)
    let tl = gsap.timeline();
    tl
    .delay(1.2)
    .fromTo([h1Ref.current, pRef.current], {autoAlpha:0, translateX: '100'}, {autoAlpha:1, translateX: '0', stagger: 0.5, duration: 2})
  
    gsap.fromTo(h1Ref.current, {y: 50}, {
      scrollTrigger:{ trigger: h1Ref.current, scrub: true, start: "top center"},
      y: -50
    })
    gsap.fromTo(pRef.current, {y: 50},{
      scrollTrigger:{ trigger: pRef.current, scrub: true, start: "top 80%"},
      y: -50
    })
  
  },[])
  return (
    <div className="hero hero1 d-flex flex-column flex-lg-row component">
      <div className="hero-left relative col-12 col-lg-6">
        <div className="trigger" ref={t1Ref} />
        <h1 className="u-h0" ref={h1Ref}>
          Your skin needs a little somethin’ somethin’.
        </h1>
        <p ref={pRef}>
          Let’s face it: you hate researching about skin care. Just give us your
          money, and we’ll help you keep your skin fresh and smooth.
        </p>
        <div className="border-gradient-rotated-top hero1-divider"></div>
      </div>
      <div className="hero-right col-12 col-lg-6 flex-grow-1">
        <Link to="/" className="link heroSiteLink">
          www.staykept.com
        </Link>
      </div>
    </div>
  )
}

export default Hero1
