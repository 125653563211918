import React, { useEffect } from 'react'
import SEO from '~/components/seo'

import { Controller } from 'react-scrollmagic'
import './index.scss'
import Intro from '../components/Intro/Intro'
import Hero1 from '../components/Hero1/Hero1'
import Hero2 from '../components/Hero2/Hero2'
import Hero3 from '../components/Hero3/Hero3'
import ATCBar from '../components/ATCBar/ATCBar'
import 'intersection-observer'
import { useInView } from 'react-intersection-observer'

const IndexPage = () => {
  const [t1Ref, t1InView] = useInView()
  const [t2Ref, t2InView] = useInView()
  const [t3Ref, t3InView] = useInView()
  useEffect(() => {
    function changeCartQuantityColor() {
      const cartQuantity = document.querySelector('.cart-quantity')
      if (t1InView) {
        cartQuantity.classList.add('black')
      } else if (t2InView) {
        cartQuantity.classList.add('black')
      } else if (t3InView) {
        cartQuantity.classList.remove('black')
      } else {
        cartQuantity.classList.remove('black')
      }
    }
    changeCartQuantityColor()
  }, [t1InView, t2InView, t3InView])
  return (
    <div className="App">
      <SEO title="Home" />
      <Controller>
        {/* <Intro /> */}
        <Hero1 t1Ref={t1Ref} />
        <Hero2 t2Ref={t2Ref} />
        <Hero3 t3Ref={t3Ref} />
        <ATCBar />
      </Controller>
    </div>
  )
}

export default IndexPage
