import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import {gsap} from 'gsap'
import hero3 from '../../images/h-hero-3.jpg'
import arrow from '../../images/arrow.png'
import './Hero3.scss'

const Hero3 = () => {
  
  const container = React.useRef();
  const h21Ref = React.useRef();
  const h22Ref = React.useRef();
  const h23Ref = React.useRef();
  const h24Ref = React.useRef();
  const h3Ref = React.useRef();

  const onMouseMove = (ev) =>{
    var decimalX = ev.clientX / window.innerWidth - 0.5;
      var decimalY = ev.clientY / window.innerHeight - 0.5;
    gsap.to([h21Ref.current, h22Ref.current, h23Ref.current, h24Ref.current, h3Ref.current], 1, { 
      x: -100 * decimalX,
      y: -100 * decimalY,
      transformPerspective: 300
    })
    
  }
  return (
    <Fragment>
      <div className="hero hero3 d-flex flex-row component tablet-and-up relative"
        onMouseMove={onMouseMove} ref={container}>
        <div className="floating-text">
          <h2 className="h2-1 u-h0" ref={h21Ref}>
            <Link to="/products/purify-face-oil/">
              Purify<span>01</span>
            </Link>
          </h2>
          <h2 className="h2-2 u-h0" ref={h22Ref}>
            <Link to="/products/renew-skin-serum/">
              Renew<span>02</span>
            </Link>
          </h2>
          <h3 className="u-h3" ref={h3Ref}>Stay classy, not ashy.</h3>
          <h2 className="h2-3 u-h0" ref={h23Ref}>
            <Link to="/products/hydrate-face-moisturizer/">
              Hydrate<span>03</span>
            </Link>
          </h2>
          <h2 className="h2-4 u-h0" ref={h24Ref}>
            <Link to="/products/rejuvenate-eye-gel/">
              Rejuvenate<span>04</span>
            </Link>
          </h2>
        </div>
        
      </div>
      <div className="hero hero3 component mobile floating-text">
        <img className="hero3-img" src={hero3} alt="Homepage" />
        <div className="d-flex">
          <h2 className="h2-1 gradient-text u-h0">
            <Link to="/products/purify-face-oil/">
              Purify<span>01</span>
            </Link>
          </h2>
          <div className="square-gradient relative">
            <div className="cta-button">
              <img src={arrow} alt="Call to action" />
            </div>
          </div>
        </div>
        <div className="border-gradient" />
        <h2 className="h2-2 gradient-text u-h0">
          <Link to="/products/renew-skin-serum/">
            Renew<span>02</span>
          </Link>
        </h2>
        <h2 className="h2-3 gradient-text u-h0">
          <Link to="/products/hydrate-face-moisturizer/">
            Hydrate<span>03</span>
          </Link>
        </h2>
        <h2 className="h2-4 gradient-text u-h0">
          <Link to="/products/rejuvenate-eye-gel/">
            Rejuvenate<span>04</span>
          </Link>
        </h2>
      </div>
    </Fragment>
  )
}

export default Hero3
