import React from 'react'
import { Link } from 'gatsby'
import {gsap} from 'gsap'
import {SplitText} from 'gsap/SplitText'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

import oval from '../../images/oval.svg'
import bottles from '../../images/bottles.png'

import './Hero2.scss'

const Hero2 = () => {
    const containerScene = React.useRef();
    const imageLayer1 = React.useRef();
    const imageLayer2 = React.useRef();
    const heading = React.useRef();
    const paragraph = React.useRef();
    /* const arched = React.useRef();
    
    React.useEffect(()=>{
      if(window) {
        gsap.registerPlugin(ScrollTrigger, SplitText)
  
        new SplitText('.arched', {
          type: 'chars',
          charsClass: 'char++',
          reduceWhiteSpace: false

        })
        
        gsap.fromTo(paragraph.current, {y: 200}, {
          scrollTrigger:{ trigger: containerScene.current, endTrigger: heading.current, scrub: true, start: "top 80%"},
          y: 0,
          duration:3
        })
        gsap.fromTo(heading.current,{bottom: '10%'}, {
          scrollTrigger:{ trigger: containerScene.current, endTrigger: heading.current, scrub: true, start: "top center"},
          bottom: '45%'
        })
        gsap.fromTo(imageLayer1.current, {y: 100}, {
          scrollTrigger:{ trigger: containerScene.current, scrub: true, start: "top center"},
          y: -100
        })
        gsap.fromTo(imageLayer2.current, {y: 150},{
          scrollTrigger:{ trigger: containerScene.current, scrub: true, start: "top 80%"},
          y: -150
        })
        gsap.fromTo(arched.current, {y: 150},{
          scrollTrigger:{ trigger: containerScene.current, scrub: true, start: "top 80%"},
          y: -70
        })
      }
    },[]) */
  
    return (
      <div
        className="hero hero2 d-flex flex-column flex-lg-row alt-bg component relative"
        ref={containerScene}
      >
        <div className="hero-left col-12 col-lg-6 left-half d-flex flex-column justify-content-center align-center">
          <div className="bottleWrapper">
            <svg
              className="arched"
              viewBox="0 0 232 36"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              transform="rotate(10)"
              style={{'overflow': 'visible'}}
            >
              <path
                id="curve"
                fill="transparent"
                d="M0.2,55.0047522 C2.81238338,52.8983074 41.8348601,20.1321211 115.7,20.5465037 C188.389567,20.9608862 228.391688,53.1745625 231.2,55.4881985"
              />
              <text
                width="232"
                fill="#3C5148"
                fontFamily="WorkSans-Regular, Work Sans"
                fontSize="24"
                letterSpacing="-1.5"
                transform="rotate(-10)"
              >
                <textPath xlinkHref="#curve">Kiss your ash goodbye</textPath>
              </text>
            </svg>
            <img className="bottle" ref={imageLayer2} src={bottles} alt="bottle" />
            <img className="oval" ref={imageLayer1} src={oval} alt="oval" />   
          </div>
          
        </div>
        <div className="hero-right col-12 col-lg-6 d-flex flex-column-reverse justify-content-around right-half">
          <h2 className="gradient-text layer u-h0" ref={heading}>
            Skin care for <br /> lazy men <br /> like us.
          </h2>
          <p className="subscription-text d-none d-md-block" ref={paragraph}>
            Subscribe to a regular supply of skin care products, and forget
            about having to buy them every now and then. You can also order
            manually if you need a booster.
          </p>
          <Link to="/" className="link heroSiteLink">
            www.staykept.com
          </Link>
        </div>
      </div>
    )
  
}

export default Hero2
